@media print {
  .inputs {
    display: none;
  }

  .content {
    max-width: 100%;
    flex-basis: 100%;
  }

  .footer {
    display: none;
  }

  .links {
    display: none;
  }

  .mobile-tabs {
    display: none !important;
  }

  .content > .tab-content > .tab-pane {
    display: block !important;
    margin-bottom: 20px;
  }

  .nav-tabs {
    display: none;
  }

  canvas {
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    height: auto!important;
    width: auto!important;
  }

  .table {
    page-break-inside: avoid;
    position: relative;
  }

  tbody {
    page-break-inside: avoid;
  }
}

.print-prime .content > .tab-content > .tab-pane {
  display: block !important;
  margin-bottom: 20px;
}
