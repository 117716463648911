/* fonts */

@font-face {
  font-family: din;
  src: url(/fonts/DIN-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: din;
  src: url(/fonts/DIN-Bold.ttf);
  font-weight: bolder;
}

@font-face {
  font-family: din;
  src: url(/fonts/DIN-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: din;
  src: url(/fonts/DIN-Regular.ttf);
}

/* MOBILE */

@media (max-width: 1031px) {
  .nav-tabs {
    display: none;
  }

  .mobile-tabs {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .mobile-tabs .nav-link.tab {
    background-color: transparent;
    font-weight: 500;
    color: #07679b;
    border: none;
    border-bottom: 2px solid #07679b;
  }

  .mobile-tabs .nav-link.arrow {
    font-size: 30px;
    height: 30px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  .mobile-tabs .nav-link.arrow button {
    color: #485465;
  }

  .mobile-tabs .nav-link.arrow button:hover {
    color: #07679b;
  }

  .tab-content {
    overflow-x: scroll;
    padding: 1rem !important;
  }
}

@media (max-width: 767.98px) {
  .modal-body {
    padding: 0.5rem !important;
  }

  .modal-footer {
    justify-content: center;
  }

  .modal-footer .btn {
    width: 100% !important;
  }

  .workday-btn {
    max-width: 14% !important;
  }

  .mobile-fs-24 {
    font-size: 24px !important;
  }

  .takeaway {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .navbar-link {
    font-size: 14px !important;
  }
}

a {
  color: #07679b;
}

strong {
  font-weight: bold;
}

#root {
  background-color: #f8f8f8;
  font-family: din;
}

body {
  color: #333333 !important;
}

/* range slider */

input[type='range'] {
  /*removes default webkit styles*/
  -webkit-appearance: none;

  margin-top: 10px;
  border-radius: 10px;
}
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 17px;
  width: 35px;
  border-radius: 0;
  background: #f1be53;
  margin-top: -6px;
  cursor: pointer;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: #ccc;
}

input[type='range']::-moz-range-track {
  width: 100%;
  height: 5px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}
input[type='range']::-moz-range-thumb {
  border: none;
  height: 17px;
  border-radius: 0;
  width: 35px;
  background: #f1be53;
  cursor: pointer;
}

/*hide the outline behind the border*/
/* input[type="range"]:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px;
} */

input[type='range']::-ms-track {
  width: 100%;
  height: 5px;

  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  background: transparent;

  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 6px 0;

  /*remove default tick marks*/
  color: transparent;
}
input[type='range']::-ms-fill-lower {
  background: #777;
  border-radius: 10px;
}
input[type='range']::-ms-fill-upper {
  background: #ddd;
  border-radius: 10px;
}
input[type='range']::-ms-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 0;
  background: #f1be53;
}
input[type='range']:focus::-ms-fill-lower {
  background: #888;
}
input[type='range']:focus::-ms-fill-upper {
  background: #ccc;
}

/* bootstrap dropdown */

.dropdown .dropdown-toggle,
.dropdown .dropdown-toggle:hover,
.dropdown .dropdown-toggle:active,
.dropdown.show > .btn.dropdown-toggle,
.dropdown.show > .btn.btn-primary.dropdown-toggle,
.dropdown .btn-primary:not(:disabled):not(.disabled).active,
.dropdown .btn-primary:not(:disabled):not(.disabled):active,
.dropdown.show > .btn-primary.dropdown-toggle,
.dropdown .btn-primary:focus {
  width: 100%;
  text-align: left;
  background-color: white;
  border: 1px solid #dbdbdb;
  color: #333;
  border-radius: 2px;
  height: 40px;
}

.dropdown .dropdown-toggle::after {
  position: relative;
  right: 0;
}

.dropdown .dropdown-menu {
  width: 100%;
  border-radius: 2px;
  border: 1px solid #dbdbdb;
}

.dropdown .dropdown-toggle::after {
  float: right;
  margin-top: 0.5em;
}

.modal-content {
  border-radius: 0;
}

/* bootstrap tabs */

.nav-tabs {
  margin-bottom: 20px;
}

.mobile-tabs {
  display: none;
}

.nav-tabs .nav-link {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.33);
  font-weight: 500;
  text-transform: uppercase;
}

.nav-tabs .nav-link.active {
  background-color: transparent;
  color: #07679b;
  border: none;
  border-bottom: 6px solid #07679b;
}

.input-card {
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 0 0 30px 0;
}

.tab-content {
  padding: 2rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.tab-content hr,
.input-card hr {
  margin-top: 3rem;
  margin-bottom: 3rem;
  border-top: 2px solid #dbdbdb;
}

.input-card p.takeaway,
.tab-content p.takeaway {
  font-size: 20px;
  text-align: center;
  padding: 1rem 2.5rem 1rem 2.5rem;
}

.chart-js-styles-for-tables {
  /* Source: https://www.chartjs.org/docs/latest/general/fonts.html */
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  color: #666;
  font-size: 16px;
  font-weight: normal;
}

.title-for-graphs-and-tables {
  color: #333;
  font-size: 16px;
  text-transform: uppercase;
}

.modal-dialog,
.modal-lg {
  max-width: 780px;
}

.modal-backdrop.show {
  background-color: #052136;
  opacity: 0.85;
}

.btn.btn-link {
  font-weight: 500;
}

/* IE compatiblity */

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .col-md-auto {
    flex-grow: 1;
    flex-basis: 0px;
  }
}

/* Buttons */

.btn-primary {
  background-color: #fbbb36;
  border-color: #fbbb36;
  color: #333;
}

.btn-secondary,
.btn-link {
  background-color: transparent;
  color: #07679b;
}

.btn-secondary {
  border-color: #dbdbdb;
}

.btn-faint {
  background-color: transparent;
  border: transparent;
  color: #666;
}

/* Hover States */
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background-color: #e59902;
  border-color: #e59902;
  color: #333;
}

button.btn-secondary:hover,
button.btn-secondary:focus,
button.btn-secondary:active {
  background-color: transparent;
  border-color: #dbdbdb;
  color: #031a2d;
}

.btn-link:hover {
  color: #031a2d;
}

.btn-faint:hover {
  color: #111;
}

.hover-button-orange:hover {
  background-color: #fddd9b !important;
}

.navbar-link:hover {
  color: #07679b !important;
}

.navbar-link.transparent {
  color: white !important;
}
.navbar-link.transparent:hover {
  opacity: 0.5;
}

.navlink-tab-hover .nav-link:hover {
  background-color: transparent;
  color: #07679b;
  border: none;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: medium;
  border-bottom: 6px solid #07679b;
}

.hover-vehicle-set-card:hover {
  background-color: #f1f1f1;
}

.hover-vehicle-set-card:hover .pencil {
  color: #0089c4;
}

.grant-card {
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 13px solid #07679b;
  background-color: white;
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 0 20px;
  color: #333;
}

.grant-card:hover {
  color: #333;
  text-decoration: none;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
}

.modal-backdrop.transparent-backdrop {
  background-color: transparent !important;
}

.font-weight-500 {
  font-weight: 500;
}

.multi-line-dropdown > .dropdown-toggle {
  white-space: normal !important;
  min-height: 40px;
  height: auto !important;
}

.multi-line-dropdown .dropdown-item {
  white-space: normal !important;
}

/* ada overrides */
.btn-outline-danger {
  color: #A51D2B;
  border-color: #A51D2B;
}

.btn-outline-secondary {
  color: #4C5457;
  border-color: #4C5457;
}
